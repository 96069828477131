function polygonreFormat(polygon) {
	let formatedPolygon = "((";
	polygon.map((point, index) => {
		formatedPolygon += point[1] + " " + point[0];
		if (index < polygon.length - 1) {
			formatedPolygon += ",";
		}
	});
	formatedPolygon += "))";
	console.log("formatedPolygon", formatedPolygon);

	return formatedPolygon;
}

export const formatPolygonService = {
	polygonreFormat,
};
