<script>
export default {
  name: "mainBodyHeader",
  props: {
    title: {
      type: String,
      default: ""
    }
  }
}
</script>

<template>
  <div class="main-card mb-3">
      <CRow align-vertical="center" align-horizontal="between" class="py-2">
        <CCol col="auto">
          <span class="ml-2">›</span>
          <span>{{ title }}</span>
        </CCol>
        <CCol>
          <slot></slot>
        </CCol>
      </CRow>
  </div>
</template>

<style scoped>
.main-card{
  border-radius: 0 !important;
  overflow: visible;
}
</style>
