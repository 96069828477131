<template>
  <CCol class="vertical-g-10">
    <div class="farm-map-box position-relative">
      <div class="farm-map main-card">
        <vue-element-loading
          :active="loading"
          :text="loadingText"
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <div id="map"></div>
      </div>
    </div>
    <CRow class="main-card overflow-visible">
      <CCol md="6" class="detail-info">
        <label for="">
          نام انتخابی زمین
          <span style="color: red">*</span>
        </label>
        <CInput
          type="text"
          class="detail-info-el-input"
          v-model="model.title"
        />
      </CCol>
      <CCol md="6" class="detail-info">
        <label for="">
          نوع محصول
          <span style="color: red">*</span>
        </label>
        <v-select
          dir="rtl"
          v-model="selectedProductType"
          :options="productTypesSource"
          placeholder="انتخاب نوع محصول..."
          class="detail-info-el"
        ></v-select>
      </CCol>
      <CCol md="6" class="detail-info mt-3">
        <label for="">
          نام محصول
          <span style="color: red">*</span>
        </label>
        <v-select
          dir="rtl"
          v-model="selectedProduct"
          :options="productsSource"
          placeholder="انتخاب محصول..."
          class="detail-info-el"
          height="10px"
        ></v-select>
      </CCol>
      <CCol md="6" class="detail-info mt-3">
        <label for="">
          نوع کشت
          <span style="color: red">*</span>
        </label>
        <v-select
          dir="rtl"
          v-model="selectedCultivationsType"
          :options="cultivationsTypeSource"
          placeholder="انتخاب نوع کشت..."
          class="detail-info-el"
        ></v-select>
      </CCol>
      <CCol md="6" class="detail-info mt-3">
        <label>
          تاریخ شروع کشت
          <span style="color: red">*</span>
        </label>
        <date-picker
          color="var(--secondary)"
          class="detail-info-el"
          v-model="model.cultivationsDate"
        ></date-picker>
      </CCol>
      <CCol md="6" class="detail-info mt-3">
        <label> تاریخ پایان کشت </label>
        <date-picker
          color="var(--secondary)"
          class="detail-info-el"
          v-model="model.cultivationsEndDate"
        ></date-picker>
      </CCol>
      <CCol col class="detail-info d-flex justify-content-end">
        <CButton color="primary" @click="submit()">ثبت زمین جدید</CButton>
      </CCol>
    </CRow>
  </CCol>
</template>

<script>
import {mapActions} from "vuex";
import cultivationsType from "../../../store/data/enum/cultivationsType.json";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {ZoomControl} from "mapbox-gl-controls";
import * as turf from "@turf/turf";
import {calculateBboxService} from "../../../services/calculateBbox.service";
import {formatPolygonService} from "../../../services/formatPolygon.service";
import polyGonStructure from "../../../store/data/polygonFeature.json";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "farmInfoCMP",
  props: ["farmId"],
  components: {
    vSelect,
  },
  data() {
    return {
      loading: false,
      loadingText: "",
      rounded_area: null,
      map: null,
      polygonCenter: null,
      selectedFarmArea: 0,
      request: {
        layerId: null,
        date: null,
        box: null,
        polygon: [],
        formatedPolygon: null,
      },
      cultivationsTypeSource: cultivationsType,
      selectedCultivationsType: "",
      productTypesSource: [],
      selectedProductType: "",
      productsSource: [],
      selectedProduct: "",
      model: {
        farmId: 0,
        title: "",
        cultivationsType: null,
        cultivationsDate: null,
        cultivationsEndDate: null,
        productTypeId: null,
        productId: null,
      },
    };
  },
  watch: {
    selectedProductType: function () {
      this.productsSource = [];
      this.selectedProduct = "";
      if (this.selectedProductType.id > 0) {
        this.getProductWithType(this.selectedProductType.id);
      }
    },
    farmId: function () {
      this.fetchData();
    },
  },
  methods: {
    ...mapActions("farm", [
      "Get",
      "GetAllProductType",
      "GetProductWithType",
      "addFarmWithArchive",
    ]),
    async fetchData() {
      this.loading = true;
      await this.getAllProductType();
      this.loadingText = "دریافت اطلاعات زمین";
      const result = await this.Get({id: this.farmId});
      this.loadingText = "دریافت اطلاعات نقشه";
      this.model.id = result.id;
      this.model.title = result.title;
      this.model.productTypeId = result.productTypeId;
      this.model.productId = result.productId;
      this.model.cultivationsType = result.cultivationsType;
      this.selectedProductType = this.productTypesSource.filter((item) => {
        return item.id == result.productTypeId;
      })[0];
      await this.getProductWithType(result.productTypeId);
      this.selectedProduct = this.productsSource.filter((item) => {
        return item.id == result.productId;
      })[0];
      this.selectedCultivationsType = this.cultivationsTypeSource.filter(
        (item) => {
          return item.value == result.cultivationsType;
        }
      )[0];
      await this.loadFarmMap(result.cordinates);
      this.map.resize();
      this.map.fitBounds(this.request.box);
      this.loading = false;
    },
    async getAllProductType() {
      var result = await this.GetAllProductType({pageNumber: 0, pageSize: 0});
      this.productTypesSource = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },
    async getProductWithType(typeId) {
      var result = await this.GetProductWithType({productTypeId: typeId});
      this.productsSource = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },
    addPolygonLayer() {
      let geojason = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [this.request.polygon],
        },
      };
      if (this.map.getSource("maine")) {
        if (this.map.getLayer("maine")) {
          this.map.removeLayer("maine");
        }
        this.map.removeSource("maine");
      }
      if (this.map.getSource("indexImage")) {
        if (this.map.getLayer("indexImage-layer")) {
          this.map.removeLayer("indexImage-layer");
        }
        this.map.removeSource("indexImage");
      }
      this.map.addSource("maine", {
        type: "geojson",
        data: geojason,
      });
      this.map.addLayer({
        id: "maine",
        type: "fill",
        source: "maine", // reference the data source
        layout: {},
        paint: {
          "fill-color": "#0080ff", // blue color fill
          "fill-opacity": 0.5,
        },
      });
    },
    calculateBbox(polygon) {
      this.request.box = calculateBboxService.calculateBox(polygon);
    },
    async loadFarmMap(cordinates) {
      const newArray = [cordinates];
      polyGonStructure.features[0].geometry.coordinates = newArray;

      let farmArea = turf.area(polyGonStructure.features[0].geometry);
      this.selectedFarmArea = Intl.NumberFormat("fa").format(
        (farmArea / 10000).toFixed(2)
      );
      this.request.polygon = cordinates;
      let polygon = turf.polygon([this.request.polygon]);
      let center = turf.centerOfMass(polygon);
      this.polygonCenter = center["geometry"]["coordinates"];
      this.request.formatedPolygon = formatPolygonService.polygonreFormat(
        this.request.polygon
      );
      this.calculateBbox(this.request.polygon);
      this.addPolygonLayer();
    },

    async submit() {
      this.loading = true;
      let farm = {
        farmId: this.farmId,
        title: this.model.title,
        cultivationsType: this.selectedCultivationsType.value,
        cultivationsDate: this.model.cultivationsDate,
        cultivationsEndDate: this.model.cultivationsEndDate,
        productTypeId: this.selectedProductType.id,
        productId: this.selectedProduct.id,
      };
      console.log("farm", farm);
      let result = await this.addFarmWithArchive({farm});
      if (result.succeeded) {
        this.$notify({
          title: "پیام سیستم",
          text: `ثبت زمین جدید انجام شد <br /><br />`,
          type: "success",
        });
        this.clearModel();
        this.$emit("closeModal");
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
      this.loading = false;
    },
    clearModel() {
      this.model.farmId = 0;
      this.model.title = "";
      this.model.cultivationsType = null;
      this.model.cultivationsDate = null;
      this.model.cultivationsEndDate = null;
      this.model.productTypeId = null;
      this.model.productId = null;
    },
  },

  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiaGFtaWRrYXplbXphZGVoIiwiYSI6ImNrcTJnNmY4NTAzczMybm85bDNvOGJ4NTYifQ.uEM3b50GGJ0aCbsXL3-O7A";
    if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
      //loaded
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true // Lazy load the plugin
      );
    }
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/hamidkazemzadeh/ckq2sozke25hf17qh16mdszbh",
      center: [53.12609431323281, 33.59332186046887], // starting position [lng, lat]
      zoom: 4,
      showZoom: true,
      attributionControl: false,
      preserveDrawingBuffer: true,
    });

    this.map.addControl(new ZoomControl(), "top-left");
    this.map.on("load", function () {
    });
  },
};
</script>
<style>
.vs__dropdown-menu {
  max-height: 16vh !important;
  min-height: 16vh !important;
}
</style>
<style scoped>
.main-card{
  padding: 15px;
}
.farm-map-box {
  height: 40dvh;
  display: flex;
  gap: 10px;

}

.farm-map {
  width: 100%;
  height: 100%;
  position: relative;
}

#map {
  height: 100%;
  position: absolute;
  inset: 0;
  width: 100%;
}

#menu {
  position: absolute;
  left: 50%;
  font-family: "Open Sans", sans-serif;
  width: 15rem;
  margin-left: -7.5rem;
}

.calculation-box {
  height: 75px;
  width: 150px;
  position: absolute;
  bottom: 40px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  text-align: center;
}
</style>
