import * as turf from "@turf/turf";

function calculateBox(polygon) {
	let line = turf.lineString(polygon);
	return turf.bbox(line);
}

export const calculateBboxService = {
	calculateBox,
};
