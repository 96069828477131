<template>
  <div class="position-relative">
    <MainBodyHeader title="لیست زمین های آرشیو شده"/>
    <div>
      <CRow>
        <CCol md="4">
          <farmList @getDetailInfo="GetFarm"/>
        </CCol>
        <CCol md="8" class="pr-0">
          <farmInfo :farmId="farmId"/>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import farmList from "./addArchive/farmList";
import farmInfo from "./addArchive/farmInfo";
import MainBodyHeader from "../../components/mainHeader.vue";

export default {
  name: "farmAddArchiveCMP",
  components: {
    MainBodyHeader,
    farmList,
    farmInfo,
  },
  data() {
    return {
      farmId: 0,
    };
  },
  methods: {
    GetFarm(farmId) {
      this.farmId = farmId;
    },
  },
};
</script>

<style scoped>


</style>
