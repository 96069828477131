<template>
  <div class="farm-list body-h-fix overflow-auto">
    <div class="farm-list-box vertical-g-10 h-100">
      <vue-element-loading
        :active="loading"
        :text="loadingText"
        spinner="bar-fade-scale"
        color="var(--secondary)"
      />
      <div v-if="!loading && farmsList.length == 0" class="d-flex flex-center flex-column h-100">
        <h4 class="emptyTitle">
          زمین آرشیو شده ای یافت نشد.
        </h4>
        <img src="/img/no-data.png" class="w-50" alt="no result">
      </div>
      <div
        class="main-card cursor-pointer"
        v-for="(item, index) in farmsList"
        :key="index"
        @click="getFarmInfo(item.farmId)"
      >
        <img
          :src="item.productImage"
          width="50"
          @error="
            () =>
              (item.productImage = require(`@/assets/img/farmImageMapBox.jpg`))
          "
         alt=""/>
        <div
          :class="
            selectedFarmItem == item.farmId
              ? 'item-info item-info-selected'
              : 'item-info'
          "
        >
          <div>
            نام زمین :
            <span>{{ item.title }}</span>
          </div>
          <div>
            محصول (نوع) -کشت :
            <span>{{ item.productName }} ({{ item.productType }}) - {{ item.cultivationsTypeTitle }}</span>
          </div>
          <div>
            تاریخ شروع کشت :<span>{{ item.cultivationsDate }}</span>
          </div>
          <div>
            تاریخ پایان کشت: <span>{{ item.cultivationsEndDate }}</span>
          </div>
          <div>
            مکان :<span>{{ item.address }}</span>
          </div>
          <div>
            هکتار : <span>{{ item.farmArea }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import polyGonStructure from "../../../store/data/polygonFeature.json";
import * as turf from "@turf/turf";
import {apiUrlRoot} from "../../../constants/config";

export default {
  name: "FarmsListCMP",
  components: {},
  data() {
    return {
      farmsList: [],
      sumAreas: 0,
      loading: false,
      loadingText: "در حال دریافت اطلاعات ...",
      selectedFarmItem: 0,
    };
  },
  methods: {
    ...mapActions("farm", ["Gets"]),

    async getFarmsList() {
      this.farmsList = [];
      this.sumAreas = 0;
      this.loading = true;
      this.loadingText = "در حال دریافت اطلاعات...";
      let result = await this.Gets({
        farmName: "",
        farmerPhone: "",
        archive: true,
      });
      console.log("result",result);
      if (result) {
        if (result.data.length > 0)
          this.farmsList = result.data.map((item, id) => {
            let polygon = item.area.substring(
              item.area.indexOf("("),
              item.area.indexOf(")")
            );
            polygon = polygon.replaceAll("(", "");
            polygon = polygon.replaceAll(")", "");
            polygon = polygon.split(", ");
            polygon = polygon.map((item) => item.split(" "));
            polyGonStructure.features[0].geometry.coordinates = [polygon];
            let farmArea = turf.area(polyGonStructure.features[0].geometry);
            item.farmArea = Intl.NumberFormat("fa").format(
              (farmArea / 10000).toFixed(2)
            );
            this.sumAreas += farmArea / 10000;
            item.productImage = `${apiUrlRoot}ProductImage/${item.productImage}`;
            return { ...item, id };
          });
      }
      this.loading = false;
    },
    async getFarmInfo(farmId) {
      this.selectedFarmItem = farmId;
      this.$emit("getDetailInfo", this.selectedFarmItem);
    },
  },
  mounted() {
    this.getFarmsList();
  },
};
</script>

<style scoped>
.main-card{
  position: relative;
}
.main-card:hover img{
  filter: grayscale(0);
}
.main-card img{
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  object-fit: contain;
  height: 100%;
  filter: grayscale(1);
  transition: all .2s;
}
</style>
